import React, {Component} from 'react';
import PropTypes, { func } from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Typography, Button,Toolbar,FormLabel,FormGroup,Switch,Select,FormControlLabel, FormControl} from '@material-ui/core';

import classNames from 'classnames';

import language from '../layout/language';
import Utils from '../api/api';
import {styles} from '../layout/theme';

class CandidateDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      _id:'',
      locations:[],
      candidate: {
        fullname: '',
        email:'',
        phone:'',
        location:'',
        cv:''
      },
      error: {
        fullname_error: false,
      },
      failed: false,
      message: '',
      isUpdate: false,
    }
  }
  componentDidMount(){
    var $this = this;
    var params = this.context.router.route.match.params;

    Utils.getData('address/cities','',function(data){
      if (data.status === "success") {
        $this.setState({ locations: data.results.cities });
      }
    });

    if(params.id!==undefined){
      this.setState({isUpdate:true},function(){
        Utils.getSingleData('candidate',params.id,function(res){
          if(res.status==='success'&&res.results!==undefined){
            var data = {}, item = res.results;
            for(var property in item) {
              if(item.hasOwnProperty(property)) {
                data[property] = item[property];
              }
            }
            if(data.phone===undefined){
              data.phone = '';
            }
            if(data.location===undefined){
              data.location = '';
            }

            $this.setState({candidate: data});
          }
        });
      });
    }

  }
  handleFieldChange(e) {
    var candidate = this.state.candidate;
    candidate[e.target.id] = e.target.value;
    this.setState({ candidate: candidate });
  }
  handleValidate(e,flag){
    var $this = this,
        candidate = $this.state.candidate,
        obj = this.state.error;
    
    candidate[e.target.id] = e.target.value;
    var p = e.target.id + '_error';
    if (e.target.value.trim() === '' || e.target.value === null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    $this.setState({error:obj});
  }
  _save(){
    var $this = this;
    var data = this.state.candidate;
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update('candidate',data, function(res) {
        //console.log(res);
        if (res.status === 'success') {
          $this.context.router.history.push('/candidate/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }
  handleSave(e){
    var params = this.context.router.route.match.params;
    
    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true})
    }
    var $this = this;
    var obj = this.state.error;
    
    obj["fullname_error"] = $this.state.candidate.fullname === "";

    this.setState({ error: obj }, function() {
      if (!$this.state.error.fullname_error) $this._save();
    });

  }
  handleCancel(){
    this.context.router.history.push('/candidate/');
  }
  render(){
    const classes = this.props.classes;
    const candidate = this.state.candidate;
    
    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">
            Cập nhật ứng viên
          </Typography>
        </Toolbar>
        <form className={classes.form} noValidate>
          <Typography
            type="body2"
            className={classNames(
              classes.error,
              !this.state.error.vn && classes.hide
            )}
          >
            {language.VN.error.require}
          </Typography>
          <Typography
            type="body2"
            className={classNames(
              classes.error,
              !this.state.failed && classes.hide
            )}
          >
            {this.state.message}
          </Typography>
          <FormGroup>
            <TextField
              id="fullname"
              label="Họ và tên ứng viên"
              className={classes.textField}
              value={candidate.fullname}
              onChange={event => this.handleValidate(event)}
              onBlur={event => this.handleValidate(event)}
              required={true}
              margin="normal"
            />
          </FormGroup>
          <FormGroup>
            <TextField
              id="email"
              label="Email"
              className={classes.textField}
              value={candidate.email}
              margin="normal"
              disabled
            />
          </FormGroup>
          <FormGroup>
            <TextField
              id="phone"
              label="Số điện thoại"
              className={classes.textField}
              value={candidate.phone}
              onChange={event => this.handleFieldChange(event)}
              margin="normal"
            />
          </FormGroup>
          <FormGroup>
            <Select id="location" native value={this.state.candidate.location} onChange={event => this.handleFieldChange(event)}>
            {this.state.locations.map((item, index) => (
              <option key={index + 1} value={item.Province_Code}>
                {item.Province}
              </option>
            ))}
            </Select>
          </FormGroup>
          <FormGroup row className={classes.formRow}>
            <Button
              color="default"
              onClick={event => this.handleCancel(event)}
            >
              {language.VN.btnCancel}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={event => this.handleSave(event, "vn")}
            >
              {language.VN.btnSave}
            </Button>
          </FormGroup>
        </form>
      </div>
    );
  }
}

CandidateDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};
CandidateDetail.contextTypes = {
  router: PropTypes.object
};
export default withStyles(styles)(CandidateDetail);
