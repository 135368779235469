import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CandidateList from './candidate_list';
import CandidateDetail from './candidate_detail';

class Candidate extends Component {
  componentDidMount(){
  }
  render() {
    //console.log(this.context);
    var params = this.context.router.route.match.params;
    var view;
    if(params.action===undefined){
      view = <CandidateList/>;
    }else{
      if(params.id===undefined){
        view = <CandidateDetail/>;
      }else{
        view = <CandidateDetail action={params.action}/>;
      }
    }
    return (
      <div className="page-wrapper">{view}</div>
    );
  }
}

Candidate.contextTypes = {
  router: PropTypes.object
};

export default Candidate;
